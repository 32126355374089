<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">Domicilio fiscal</h2>
			<p class="text-format-2x">
				Conforme a la legislación tributaria,
				{{ banco }} tiene la obligación de identificar la residencia fiscal de sus clientes, y
				suministrar información a la Administración Tributaria sobre las mismas. El declarante
				se compromete a comunicar a {{ banco }} cualquier variación que se produzca en los datos
				a la mayor brevedad y en cualquier caso en un plazo máximo de 30 días hábiles a contar
				desde que tenga lugar el acto que produzca la variación en los datos y / o información a
				que se refiera esta cláusula y a proporcionar cuanta información le requiera {{ banco }}
				para cumplir con las obligaciones del contrato.
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				@click.prevent="closeModal"
				class="button-confirm mb-5"
				type="button"
			>
				ENTENDIDO
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-domicilio-fiscal',

	computed: {
		banco({
			$store: {
				state: {
					app: { companyName },
				},
			},
		}) {
			return companyName;
		},
	},

	methods: {
		closeModal() {
			this.$store.dispatch('modal/close');
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-header span {
	font-size: 37px;
	color: #c9c9c9;
}
.modal-body {
	margin: 10px 30px;
}
@media screen and (max-device-width: 480px) {
	button {
		width: 90%;
	}
}
</style>
