<template>
	<div id="app-address">
		<call-help></call-help>
		<app-stepper :step-number="2"></app-stepper>
		<div class="row flex-column align-items-center mainBox">
			<h2 class="col-11 col-xl-8 mb-4 text-center text-format-1">
				Domicilio fiscal
				<img
					data-test-id="tip-fiscal"
					@click="openHelpDomicilioFiscal"
					alt
					class="ml-2 mb-1"
					src="@/assets/images/tip.svg"
					width="20px"
				/>
			</h2>
			<form class="d-flex flex-column align-items-center">
				<div class="row justify-content-center flex-column flex-md-row w-100">
					<div class="col">
						<div class="form-group">
							<label
								for="direccion_fiscal_via"
								:class="{ invalidForm: addressStreetTypeError }"
							>
								*Tipo de vía
							</label>
							<select
								data-test-id="street-type"
								class="form-control"
								:class="{ invalidInfoBox: addressStreetTypeError }"
								v-model="$v.addressStreetType.$model"
								tabindex="1"
							>
								<option
									disabled
									value="undefined"
								></option>
								<option value></option>
								<option value="AV">Avenida</option>
								<option value="CL">Calle</option>
								<option value="CK">Callejon</option>
								<option value="CM">Camino</option>
								<option value="CR">Carretera</option>
								<option value="PS">Paseo</option>
								<option value="PJ">Pasaje</option>
								<option value="PZ">Plaza</option>
								<option value="RB">Rambla</option>
								<option value="RD">Ronda</option>
							</select>
							<div class="space-error">
								<span
									v-if="addressStreetTypeError"
									class="mt-2 invalidInfo help-block"
								>
									{{ addressStreetTypeError }}
								</span>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<label
								for="direccion_fiscal_domicilio"
								:class="{ invalidForm: addressStreetNameError }"
							>
								*Nombre de vía
							</label>
							<input
								class="form-control"
								:class="{ invalidInfoBox: addressStreetNameError }"
								id="via-nombre"
								v-model.trim="addressStreetName"
								@blur="$v.addressStreetName.$touch()"
								maxlength="50"
								placeholder
								tabindex="1"
								type="text"
								autocomplete="street-address"
							/>
							<div class="space-error">
								<span
									v-if="addressStreetNameError"
									class="mt-2 invalidInfo help-block"
								>
									{{ addressStreetNameError }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center flex-column flex-md-row w-100">
					<div class="col">
						<div class="form-row">
							<div class="form-group col-2">
								<label
									for="direccion_fiscal_numero"
									:class="{ invalidForm: addressStreetNumberError }"
								>
									Nº
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: addressStreetNumberError }"
									id="direccion_fiscal_numero"
									v-model.trim="addressStreetNumber"
									@blur="$v.addressStreetNumber.$touch()"
									maxlength="4"
									placeholder
									tabindex="1"
									type="text"
								/>
								<div class="text-nowrap space-error">
									<span
										v-if="addressStreetNumberError"
										class="mt-2 invalidInfo help-block"
									>
										{{ addressStreetNumberError }}
									</span>
								</div>
							</div>
							<div class="form-group col-4">
								<label
									for="direccion_fiscal_portal"
									:class="{ invalidForm: addressPortalError }"
								>
									Portal
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: addressPortalError }"
									id="direccion_fiscal_portal"
									v-model.trim="addressPortal"
									@blur="$v.addressPortal.$touch()"
									maxlength="2"
									placeholder
									tabindex="1"
									type="text"
								/>
							</div>
							<div class="form-group col-3">
								<label
									for="direccion_fiscal_piso"
									:class="{ invalidForm: addressFloorError }"
								>
									Piso
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: addressFloorError }"
									id="direccion_fiscal_piso"
									v-model.trim="addressFloor"
									@blur="$v.addressFloor.$touch()"
									maxlength="3"
									placeholder
									tabindex="1"
									type="text"
								/>
							</div>
							<div class="form-group col-3">
								<label
									for="direccion_fiscal_puerta"
									:class="{ invalidForm: addressDoorError }"
								>
									Puerta
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: addressDoorError }"
									id="direccion_fiscal_puerta"
									v-model.trim="addressDoor"
									@blur="$v.addressDoor.$touch()"
									maxlength="2"
									placeholder
									tabindex="1"
									type="text"
								/>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<label
								for="direccion_fiscal_complemento"
								:class="{ invalidForm: addressAdditionalInfoError }"
							>
								Información adicional
							</label>
							<input
								class="form-control"
								:class="{ invalidInfoBox: addressAdditionalInfoError }"
								id="direccion_fiscal_complemento"
								v-model.trim="addressAdditionalInfo"
								@blur="$v.addressAdditionalInfo.$touch()"
								maxlength="25"
								placeholder
								tabindex="1"
								type="text"
							/>
							<div class="space-error">
								<span
									v-if="addressAdditionalInfoError"
									class="mt-2 invalidInfo help-block"
								>
									{{ addressAdditionalInfoError }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center flex-column flex-md-row w-100">
					<div class="col">
						<div class="form-group">
							<label
								for="direccion_fiscal_cp"
								:class="{ invalidForm: addressPostalCodeError }"
							>
								*Código Postal
							</label>
							<input
								class="form-control"
								:class="{ invalidInfoBox: addressPostalCodeError }"
								id="direccion_fiscal_cp"
								v-model.trim="addressPostalCode"
								@blur="handleBlurFiscalAddressPostalCode"
								maxlength="5"
								placeholder
								tabindex="1"
								type="text"
								autocomplete="postal-code"
							/>
							<div class="space-error">
								<span
									v-if="addressPostalCodeError"
									class="mt-2 invalidInfo help-block"
								>
									{{ addressPostalCodeError }}
								</span>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<label
								for="direccion_fiscal_poblacion"
								:class="{ invalidForm: addressPlaceError }"
							>
								*Población
							</label>
							<select
								v-if="!showInputFiscal"
								v-model.trim="addressPlace"
								class="form-control"
								:class="{ invalidInfoBox: addressPlaceError }"
								id="direccion_fiscal_poblacion"
							>
								<option
									v-for="(option, index) in placesFiscalAddress"
									:key="index"
									:value="option.place"
								>
									{{ option.place }}
								</option>
							</select>
							<input
								v-else
								class="form-control"
								:class="{ invalidInfoBox: addressPlaceError }"
								id="direccion_fiscal_poblacion"
								v-model.trim="addressPlace"
								placeholder
								tabindex="1"
								type="text"
							/>
							<div class="space-error">
								<span
									v-if="addressPlaceError"
									class="mt-2 invalidInfo help-block"
								>
									{{ addressPlaceError }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<h2 class="text-center text-format-1">
					Domicilio Postal
					<img
						data-test-id="tip-postal"
						@click="openHelpDomicilioPostal"
						alt
						class="ml-2 mb-1"
						src="@/assets/images/tip.svg"
						width="20px"
					/>
				</h2>
				<div class="d-flex justify-content-center mt-4 mb-4 ml-4 mr-4">
					<label class="containerBox">
						<input
							data-test-id="same-address-checkbox"
							v-model="postalAddressSameAddress"
							type="checkbox"
							tabindex="1"
						/>
						<span class="checkmarkBF"></span>
					</label>
					<p class="block m-0 tf">
						Quiero utilizar los mismos datos que los de mi domicilio fiscal
					</p>
				</div>
				<div
					v-if="!postalAddressSameAddress"
					class="d-flex flex-column align-items-center w-100"
				>
					<div class="row justify-content-center flex-column flex-md-row w-100">
						<div class="col">
							<div class="form-group">
								<label
									for="direccion_postal_via"
									:class="{ invalidForm: postalAddressStreetTypeError }"
								>
									*Tipo de vía
								</label>
								<select
									class="form-control"
									:class="{ invalidInfoBox: postalAddressStreetTypeError }"
									v-model="$v.postalAddressStreetType.$model"
									tabindex="1"
								>
									<option
										disabled
										value="undefined"
									></option>
									<option value></option>
									<option value="AV">Avenida</option>
									<option value="CL">Calle</option>
									<option value="CK">Callejon</option>
									<option value="CM">Camino</option>
									<option value="CR">Carretera</option>
									<option value="PS">Paseo</option>
									<option value="PJ">Pasaje</option>
									<option value="PZ">Plaza</option>
									<option value="RB">Rambla</option>
									<option value="RD">Ronda</option>
								</select>
								<div class="space-error">
									<span
										v-if="postalAddressStreetTypeError"
										class="mt-2 invalidInfo help-block"
									>
										{{ postalAddressStreetTypeError }}
									</span>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label
									for="direccion_postal_domicilio"
									:class="{ invalidForm: postalAddressStreetNameError }"
								>
									*Nombre de vía
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: postalAddressStreetNameError }"
									id="via-nombre"
									v-model.trim="postalAddressStreetName"
									@blur="$v.postalAddressStreetName.$touch()"
									maxlength="50"
									placeholder
									tabindex="1"
									type="text"
								/>
								<div class="space-error">
									<span
										v-if="postalAddressStreetNameError"
										class="mt-2 invalidInfo help-block"
									>
										{{ postalAddressStreetNameError }}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center flex-column flex-md-row w-100">
						<div class="col">
							<div class="form-row">
								<div class="form-group col-2">
									<label
										for="direccion_postal_numero"
										:class="{ invalidForm: postalAddressStreetNumberError }"
									>
										Nº
									</label>
									<input
										class="form-control"
										:class="{ invalidInfoBox: postalAddressStreetNumberError }"
										id="direccion_postal_numero"
										v-model.trim="postalAddressStreetNumber"
										@blur="$v.postalAddressStreetNumber.$touch()"
										maxlength="4"
										placeholder
										tabindex="1"
										type="text"
									/>
									<div class="text-nowrap space-error">
										<span
											v-if="postalAddressStreetNumberError"
											class="mt-2 invalidInfo help-block"
										>
											{{ postalAddressStreetNumberError }}
										</span>
									</div>
								</div>
								<div class="form-group col-4">
									<label
										for="direccion_postal_portal"
										:class="{ invalidForm: postalAddressPortalError }"
									>
										Portal
									</label>
									<input
										class="form-control"
										:class="{ invalidInfoBox: postalAddressPortalError }"
										id="direccion_postal_portal"
										v-model.trim="postalAddressPortal"
										@blur="$v.postalAddressPortal.$touch()"
										maxlength="4"
										placeholder
										tabindex="1"
										type="text"
									/>
								</div>
								<div class="form-group col-3">
									<label
										for="direccion_postal_piso"
										:class="{ invalidForm: postalAddressFloorError }"
									>
										Piso
									</label>
									<input
										class="form-control"
										:class="{ invalidInfoBox: postalAddressFloorError }"
										id="direccion_postal_piso"
										v-model.trim="postalAddressFloor"
										@blur="$v.postalAddressFloor.$touch()"
										maxlength="5"
										placeholder
										tabindex="1"
										type="text"
									/>
								</div>
								<div class="form-group col-3">
									<label
										for="direccion_postal_puerta"
										:class="{ invalidForm: postalAddressDoorError }"
									>
										Puerta
									</label>
									<input
										class="form-control"
										:class="{ invalidInfoBox: postalAddressDoorError }"
										id="direccion_postal_puerta"
										v-model.trim="postalAddressDoor"
										@blur="$v.postalAddressDoor.$touch()"
										maxlength="5"
										placeholder
										tabindex="1"
										type="text"
									/>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label
									for="direccion_postal_complemento"
									:class="{ invalidForm: postalAddressAdditionalInfoError }"
								>
									Información adicional
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: postalAddressAdditionalInfoError }"
									id="direccion_postal_complemento"
									v-model.trim="postalAddressAdditionalInfo"
									@blur="$v.postalAddressAdditionalInfo.$touch()"
									maxlength="25"
									placeholder
									tabindex="1"
									type="text"
								/>
								<div class="space-error">
									<span
										v-if="postalAddressAdditionalInfoError"
										class="mt-2 invalidInfo help-block"
									>
										{{ postalAddressAdditionalInfoError }}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center flex-column flex-md-row w-100">
						<div class="col">
							<div class="form-group">
								<label
									for="direccion_postal_cp"
									:class="{ invalidForm: postalAddressPostalCodeError }"
								>
									*Código Postal
								</label>
								<input
									class="form-control"
									:class="{ invalidInfoBox: postalAddressPostalCodeError }"
									id="direccion_postal_cp"
									v-model.trim="postalAddressPostalCode"
									@blur="handleBlurPostalAdressPostalCode"
									maxlength="5"
									placeholder
									tabindex="1"
									type="text"
								/>
								<div class="space-error">
									<span
										v-if="postalAddressPostalCodeError"
										class="mt-2 invalidInfo help-block"
									>
										{{ postalAddressPostalCodeError }}
									</span>
								</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<label
									for="direccion_postal_poblacion"
									:class="{ invalidForm: postalAddressPlaceError }"
								>
									*Población
								</label>
								<select
									v-if="!showInputPostal"
									class="form-control"
									:class="{ invalidInfoBox: postalAddressPlaceError }"
									id="direccion_postal_poblacion"
									v-model.trim="postalAddressPlace"
								>
									<option
										v-for="(option, index) in placesPostalAddress"
										:key="index"
										:value="option.place"
									>
										{{ option.place }}
									</option>
								</select>
								<input
									v-else
									class="form-control"
									:class="{ invalidInfoBox: postalAddressPlaceError }"
									id="direccion_postal_poblacion"
									v-model.trim="postalAddressPlace"
									placeholder
									tabindex="1"
									type="text"
								/>
								<div class="space-error">
									<span
										v-if="postalAddressPlaceError"
										class="mt-2 invalidInfo help-block"
									>
										{{ postalAddressPlaceError }}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="confirm-button-group">
					<button
						class="button-confirm my-3 marginB mb-sm-1"
						type="submit"
						:disabled="isButtonDisabled"
						data-testid="confirm-button"
						@click.prevent="submit"
					>
						CONTINUAR
					</button>
				</div>
				<app-mobile-call></app-mobile-call>
			</form>
		</div>
	</div>
</template>

<script>
import CallHelp from '@/components/call-help';
import AppMobileCall from '@/components/app-mobile-call';
import AppStepper from '@/components/app-stepper';
import { required, helpers } from 'vuelidate/lib/validators';
import { postalCode } from '@/utils/validators';
import modalDomicilioFiscal from '@/modals/m-domicilio-fiscal';
import modalDomicilioPostal from '@/modals/m-domicilio-postal';
import { mapErrorFields } from '@/utils/helpers';

const customAddressValidator = helpers.regex('customAddressValidator', /^[a-zA-Z0-9À-ž\s/-]*$/);
const customAddressFloorValidator = helpers.regex(
	'customAddressFloorValidator',
	/^[a-zA-Z0-9À-ž\s/º-]*$/
);

export default {
	name: 'app-address',

	components: {
		CallHelp,
		AppStepper,
		AppMobileCall,
	},

	data() {
		return {
			postalAddressSameAddress: true,
			addressStreetType: '',
			addressStreetName: '',
			addressStreetNumber: '',
			addressPortal: '',
			addressFloor: '',
			addressDoor: '',
			addressAdditionalInfo: '',
			addressPostalCode: '',
			addressPlace: '',
			postalAddressStreetType: '',
			postalAddressStreetName: '',
			postalAddressStreetNumber: '',
			postalAddressPortal: '',
			postalAddressFloor: '',
			postalAddressDoor: '',
			postalAddressAdditionalInfo: '',
			postalAddressPostalCode: '',
			postalAddressPlace: '',
			isButtonDisabled: false,
			placesFiscalAddress: [],
			placesPostalAddress: [],
			showInputPostal: false,
			showInputFiscal: false,
		};
	},

	validations: {
		addressStreetType: {
			required,
		},
		addressStreetName: {
			required,
			customAddressValidator,
		},
		addressStreetNumber: {
			customAddressValidator,
		},
		addressPortal: {
			customAddressValidator,
		},
		addressFloor: {
			customAddressFloorValidator,
		},
		addressDoor: {
			customAddressValidator,
		},
		addressAdditionalInfo: {
			customAddressValidator,
		},
		addressPostalCode: {
			required,
			postalCode,
		},
		addressPlace: {
			required,
		},
		postalAddressStreetType: {
			required,
		},
		postalAddressStreetName: {
			required,
			customAddressValidator,
		},
		postalAddressStreetNumber: {
			customAddressValidator,
		},
		postalAddressPortal: {
			customAddressValidator,
		},
		postalAddressFloor: {
			customAddressFloorValidator,
		},
		postalAddressDoor: {
			customAddressValidator,
		},
		postalAddressAdditionalInfo: {
			customAddressValidator,
		},
		postalAddressPostalCode: {
			required,
			postalCode,
		},
		postalAddressPlace: {
			required,
		},
	},

	computed: {
		...mapErrorFields([
			'addressStreetType',
			'addressStreetName',
			'addressStreetNumber',
			'addressPortal',
			'addressFloor',
			'addressDoor',
			'addressAdditionalInfo',
			'addressPostalCode',
			'addressPlace',
			'postalAddressStreetType',
			'postalAddressStreetName',
			'postalAddressStreetNumber',
			'postalAddressPortal',
			'postalAddressFloor',
			'postalAddressDoor',
			'postalAddressAdditionalInfo',
			'postalAddressPostalCode',
			'postalAddressPlace',
		]),
	},

	methods: {
		openHelpDomicilioFiscal() {
			this.$store.dispatch('modal/open', modalDomicilioFiscal);
		},

		openHelpDomicilioPostal() {
			this.$store.dispatch('modal/open', modalDomicilioPostal);
		},

		submit() {
			const { $v, $router } = this;

			if (this.postalAddressSameAddress) {
				this.copyAddressData();
			}

			const {
				postalAddressStreetType,
				addressStreetType,
				postalAddressStreetName,
				addressStreetName,
				postalAddressStreetNumber,
				addressStreetNumber,
				postalAddressPortal,
				addressPortal,
				postalAddressFloor,
				addressFloor,
				postalAddressDoor,
				addressDoor,
				postalAddressAdditionalInfo,
				addressAdditionalInfo,
				postalAddressPostalCode,
				addressPostalCode,
				postalAddressPlace,
				addressPlace,
			} = this;

			$v.$touch();

			if (!$v.$invalid) {
				this.isButtonDisabled = true;
				this.$store
					.dispatch('onboarding/saveProcess', {
						fiscalAddress: {
							streetType: addressStreetType,
							streetName: addressStreetName,
							streetNumber: addressStreetNumber,
							portal: addressPortal,
							door: addressDoor,
							floor: addressFloor,
							additionalInfo: addressAdditionalInfo,
							postalCode: addressPostalCode,
							place: addressPlace,
							country: 'ES',
						},
						postalAddress: {
							streetType: postalAddressStreetType,
							streetName: postalAddressStreetName,
							streetNumber: postalAddressStreetNumber,
							portal: postalAddressPortal,
							door: postalAddressDoor,
							floor: postalAddressFloor,
							additionalInfo: postalAddressAdditionalInfo,
							postalCode: postalAddressPostalCode,
							place: postalAddressPlace,
							country: 'ES',
						},
					})
					.then(() => $router.push('app-professional-data'))
					.catch(() => {})
					.finally(() => {
						this.isButtonDisabled = false;
					});
			}
		},

		copyAddressData() {
			this.postalAddressStreetType = this.addressStreetType;
			this.postalAddressStreetName = this.addressStreetName;
			this.postalAddressStreetNumber = this.addressStreetNumber;
			this.postalAddressPortal = this.addressPortal;
			this.postalAddressFloor = this.addressFloor;
			this.postalAddressDoor = this.addressDoor;
			this.postalAddressAdditionalInfo = this.addressAdditionalInfo;
			this.postalAddressPostalCode = this.addressPostalCode;
			this.postalAddressPlace = this.addressPlace;
		},

		async handleBlurFiscalAddressPostalCode() {
			const { $v } = this;
			this.addressPlace = '';

			$v.addressPostalCode.$touch();
			if (!$v.addressPostalCode.$invalid) {
				this.placesFiscalAddress = await this.getPlaces(this.addressPostalCode, true);
			}
		},

		async handleBlurPostalAdressPostalCode() {
			const { $v } = this;
			this.postalAddressPlace = '';

			$v.postalAddressPostalCode.$touch();
			if (!$v.postalAddressPostalCode.$invalid) {
				this.placesPostalAddress = await this.getPlaces(this.postalAddressPostalCode, false);
			}
		},

		async getPlaces(postalCode, isFiscal) {
			try {
				const { data: data } = await this.$store.dispatch('onboarding/getPlaces', postalCode);

				isFiscal ? (this.showInputFiscal = false) : (this.showInputPostal = false);
				return data;
			} catch {
				isFiscal ? (this.showInputFiscal = true) : (this.showInputPostal = true);
				return [];
			}
		},
	},

	watch: {
		postalAddressSameAddress(value) {
			if (value) {
				this.copyAddressData();
			} else {
				this.postalAddressStreetType = '';
				this.postalAddressStreetName = '';
				this.postalAddressStreetNumber = '';
				this.postalAddressPortal = '';
				this.postalAddressFloor = '';
				this.postalAddressDoor = '';
				this.postalAddressAdditionalInfo = '';
				this.postalAddressPostalCode = '';
				this.postalAddressPlace = '';
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.mainBox {
	margin: 20px 0 0;
	padding-bottom: 20px;
}
label {
	white-space: nowrap;
}
img {
	cursor: pointer;
}
.containerBox {
	margin-top: 1px;
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
}
.containerBox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.checkmarkBF {
	position: absolute;
	top: 0;
	left: 0;
	height: 21px;
	width: 21px;
	border: 2px solid #113456;
}
.containerBox input:checked ~ .checkmarkBF {
	background-color: #fff;
}
.checkmarkBF:after {
	content: '';
	position: absolute;
	display: none;
}
.containerBox input:checked ~ .checkmarkBF:after {
	display: block;
}
.containerBox .checkmarkBF:after {
	left: 6px;
	top: 1px;
	width: 6px;
	height: 12px;
	border: solid $color-primary;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
option {
	color: #113456 !important;
	font-family: 'Poppins', sans-serif !important;
	background: #fff;
}
.space-error {
	padding: 6px 0;
	position: relative;
}
.space-error span {
	font-size: 12px;
	position: absolute;
	top: -7px;
}

form {
	width: 100%;
}

.confirm-button-group {
	width: 100%;
	padding: 0 14px;
	text-align: center;
}

@media screen and (max-device-width: 480px) {
	.marginB {
		margin-bottom: 50px !important;
	}
	.checkmark {
		height: 18px;
		width: 18px;
	}
	.containerBox .checkmark:after {
		left: 5px;
		top: 1px;
		width: 5px;
		height: 11px;
	}
}
</style>
