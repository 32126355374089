<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">Domicilio Postal</h2>
			<p class="text-format-2x">
				Asegúrate, por favor, de que tu Domicilio postal esté correcto. Ten en cuenta que a esta
				dirección te enviaremos comunicaciones postales importantes, como por ejemplo tu tarjeta
				de débito.
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				@click.prevent="closeModal"
				class="button-confirm mb-5"
				type="button"
			>
				ENTENDIDO
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-domicilio-postal',

	methods: {
		closeModal() {
			this.$store.dispatch('modal/close');
		},
	},
};
</script>
<style lang="scss" scoped>
.modal-header span {
	font-size: 37px;
	color: #c9c9c9;
}
.modal-body {
	margin: 10px 30px;
}
@media screen and (max-device-width: 480px) {
	button {
		width: 90%;
	}
}
</style>
